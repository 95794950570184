'use client'
import { cn } from '@/util/cn'
import Link from 'next/link'
import { AnchorHTMLAttributes, ButtonHTMLAttributes, ComponentProps } from 'react'
import { useFormStatus } from 'react-dom'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>
type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>

const buttonClassName = 'text-giphyWhite bg-gradient-primary font-bold px-4 py-2'

type LinkProps = ComponentProps<typeof Link>

type Props = ButtonProps | AnchorProps | LinkProps
export default function Button({ isExternal, className, children, ...props }: { isExternal?: boolean } & Props) {
    const c = cn(buttonClassName, className)
    return (props as LinkProps).href && !isExternal ? (
        <Link className={c} {...(props as LinkProps)}>
            {children}
        </Link>
    ) : (props as AnchorProps).href ? (
        <a className={c} {...(props as AnchorProps)}>
            {children}
        </a>
    ) : (
        <button className={c} {...(props as ButtonProps)}>
            {children}
        </button>
    )
}

export function SubmitButton({ className, children, ...props }: ButtonProps) {
    const { pending } = useFormStatus()
    return (
        <button
            type="submit"
            className={cn(buttonClassName, className, pending && `pointer-events-none bg-opacity-50`)}
            {...props}
        >
            {children}
        </button>
    )
}
