'use client'
import { cn } from '@/util/cn'
import useClientRender from '@/util/use-client-render'
import { IChannel } from '@giphy/js-types'
import { Dispatch, ReactNode, SetStateAction, createContext, useContext } from 'react'
import { createPortal } from 'react-dom'
import { CloseIcon, NavigateLeftIcon } from 'ui/src/icons/utility'

export type ModalType =
    | { name: 'createCollection'; title?: string; channel?: IChannel['id'] }
    | {
          title?: string
          name: 'addToCollection'
          channel?: IChannel['id']
      }
    | { name: 'editCollection'; channel: IChannel['id']; title?: string }
    | null

type Props = {
    children: ReactNode
    title: string
    previousModalId?: ModalType | null
    className?: string
    contentClassName?: string
    goBack?: () => void
    onClose: () => void
}

type ModalContextType = {
    modal: ModalType
    setModalType: Dispatch<SetStateAction<ModalType>>
    goBack: () => void
}
export const ModalContext = createContext<ModalContextType>({
    modal: null,
    setModalType: () => {},
    goBack: () => {},
})

export const modalClassName = `bg-giphyBlack z-modal fixed inset-0 flex items-center justify-center bg-opacity-80`
export function Modal({
    children,
    title,
    previousModalId = null,
    className,
    contentClassName,
    goBack,
    onClose,
}: Props) {
    const isClient = useClientRender()
    return isClient
        ? createPortal(
              <div className={modalClassName}>
                  <div
                      className={cn(
                          'bg-giphyDarkestGrey flex min-h-[390px] w-5/6 flex-col p-3 md:w-[520px]',
                          className
                      )}
                  >
                      <div className="flex flex-col">
                          <div className="flex justify-between">
                              {previousModalId && (
                                  <div
                                      className="cursor-pointer"
                                      onClick={(e) => {
                                          e.preventDefault()
                                          e.stopPropagation()
                                          goBack?.()
                                      }}
                                  >
                                      <NavigateLeftIcon height={18} />
                                  </div>
                              )}
                              <h1 className="box-border flex max-h-[23px] max-w-[400px] select-none flex-row overflow-hidden whitespace-nowrap text-base">
                                  {title}
                              </h1>
                              <div
                                  onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      onClose()
                                  }}
                                  className="cursor-pointer"
                              >
                                  <CloseIcon height={18} />
                              </div>
                          </div>
                          <hr className="bg-giphyDarkGrey h-0.5 w-full" />
                      </div>
                      <div className={cn('overflow-auto', contentClassName)}>{children}</div>
                  </div>
              </div>,
              document.querySelector('body')!
          )
        : null
}

// useful for server components
export const ClickToShowModal = ({
    children,
    modal: modalId,
    className,
}: {
    modal: ModalType
    children: ReactNode
    className?: string
}) => {
    const { setModalType } = useContext(ModalContext)
    return (
        <div
            className={className}
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setModalType(modalId)
            }}
        >
            {children}
        </div>
    )
}
