import { Gradient } from 'types/src/gradient'
import { Rating } from '../types'

export function descriptionFromRating(rating?: Rating): string {
    switch (rating) {
        case 'g':
            return 'Content that is appropriate for all ages.'
        case 'pg':
            return 'Content that is generally safe for everyone, but may require parental preview before children can watch.'
        case 'pg13':
            return 'Mild sexual innuendos, mild substance use, mild profanity, or threatening images. May include images of semi-naked people, but DOES NOT show real human genitalia or nudity.'
        case 'r':
            return 'Strong language, strong sexual innuendo, violence, and illegal drug use; not suitable for teens or younger. NO NUDITY.'
        default:
            return 'Content that has not been rated.'
    }
}

export function gradientFromRating(rating?: Rating): Gradient | undefined {
    switch (rating) {
        case 'g':
            return 'indigo-blue'
        case 'pg':
            return 'indigo-purple'
        case 'pg13':
            return 'red-yellow'
        case 'r':
            return 'red-pink'
        default:
            return
    }
}

export function labelFromRating(rating?: Rating): string {
    switch (rating) {
        case 'g':
            return 'G'
        case 'pg':
            return 'PG'
        case 'pg13':
            return 'PG-13'
        case 'r':
            return 'R'
        default:
            return 'Unrated'
    }
}

export function ratingFromString(string: string): Rating {
    switch (string.toLowerCase().trim()) {
        case 'g':
            return 'g'
        case 'pg':
            return 'pg'
        case 'pg-13':
        case 'pg13':
            return 'pg13'
        case 'r':
            return 'r'
        default:
            return 'unrated'
    }
}
